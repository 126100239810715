<template>
  <div class="myAssAppeal">
    <!-- 绩效申诉 -->
    <van-nav-bar
      :title="$t('jxMyAssResultsAppeal.araTxt1')"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    />
    <div class="content">
      <div class="app_details">
        <van-field
          class="empcode"
          :label="$t('jxMyAssResultsAppeal.araTxt2')"
          :value="userInfo.empcode"
          readonly
        />
        <van-field
          :label="$t('jxMyAssResultsAppeal.araTxt3')"
          :value="userInfo.empname"
          readonly
        />
        <van-field
          :label="$t('jxMyAssResultsAppeal.araTxt4')"
          :value="khtimename"
          readonly
        />
        <van-field
          :label="$t('jxMyAssResultsAppeal.araTxt5')"
          :value="planname"
          readonly
        />
        <van-field
          :label="
            sstype == '按指标申诉'
              ? $t('jxMyAssResultsAppeal.araTxt6')
              : $t('jxMyAssResultsAppeal.araTxt7')
          "
          :value="khres"
          readonly
        />
        <van-field
          :label="
            sstype == '按指标申诉'
              ? $t('jxMyAssResultsAppeal.araTxt8')
              : $t('jxMyAssResultsAppeal.araTxt9')
          "
          :value="lastscore"
          readonly
        />
        <van-field
          v-if="sstype == '按指标申诉'"
          :label="$t('jxMyAssResultsAppeal.araTxt10')"
          :value="itemper"
          readonly
        />
        <van-field
          v-if="sstype == '按指标申诉'"
          :label="$t('jxMyAssResultsAppeal.araTxt11')"
          :value="tgvalue"
          readonly
        />
        <div class="presonAvar">
          <img style="border-radius: 100%" :src="loginUserImage" />
        </div>
      </div>
      <div class="app_reason">
        <!-- <div class="text">期望等级：</div> -->
        <!-- <div class="reqkhres"> -->
        <van-field
          class="qwdflll"
          v-model="reqkhscore"
          autosize
          :label="$t('jxMyAssResultsAppeal.araTxt12')"
          type="input"
          show-word-limit
          v-if="sstype != '按结果申诉'"
        />
        <p v-if="sstype != '按结果申诉'" class="pppppp">
          {{ $t('jxMyAssResultsAppeal.araTxt29') }}
        </p>
        <!-- {{$t('jxMyAssResultsAppeal.araTxt18')}} ~ {{ qiwangScore }}{{$t('jxMyAssResultsAppeal.araTxt19')}} -->
        <van-field
          class="reqkhres_cell"
          :value="reqkhres"
          readonly
          :label="$t('jxMyAssResultsAppeal.araTxt13')"
          right-icon="arrow"
          :placeholder="$t('jxMyAssResultsAppeal.araTxt14')"
          @click="showLevelPicker"
          v-if="sstype == '按结果申诉'"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="levelColumns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <!-- </div> -->
        <div class="text">{{ $t('jxMyAssResultsAppeal.araTxt15') }}</div>
        <div class="thrid_desc">
          <van-field
            class="brief"
            v-model="apreason"
            rows="5"
            autosize
            label
            type="textarea"
            show-word-limit
            style="margin-bottom: 15px"
          />
        </div>
      </div>
      <div class="app_desc">
        <div class="text">{{ $t('jxMyAssResultsAppeal.araTxt16') }}</div>
        <div class="thrid_desc">
          <van-field
            class="brief"
            v-model="remark"
            rows="3"
            autosize
            label
            type="textarea"
            show-word-limit
            style="margin-bottom: 15px"
          />
        </div>
      </div>
      <div class="app_button">
        <van-button type="info" block :disabled="!disabled" @click="submit">{{
          $t('jxMyAssResultsAppeal.araTxt17')
        }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getJxLevel, postJxSs } from '@api/wxjx.js'
export default {
  data() {
    let khtimeid = this.$route.query.khtimeid
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      khtimeid,
      is360: this.$route.query.is360,
      mautoid: this.$route.query.mautoid,
      empname: this.$route.query.empname,
      empcode: this.$route.query.empcode,
      khtimename: this.$route.query.khtimename,
      planname: this.$route.query.planname,
      lastscore: this.$route.query.lastscore,
      khres: this.$route.query.khres,
      sstype: this.$route.query.sstype,
      isshensu: this.$route.query.isshensu,
      itemautoid: this.$route.query.itemautoid,
      tgvalue: this.$route.query.tgvalue,
      itemper: this.$route.query.itemper,
      realscore: this.$route.query.realscore,
      appDetails: {},
      reqkhres: '',
      apreason: '',
      remark: '',
      showPicker: false,
      levelColumns: [],
      loginUserImage: '',
      reqkhscore: 0,
      tgvalueShow: false,
      disableds: false
    }
  },
  computed: {
    disabled() {
      return this.apreason && this.disableds
    },
    qiwangScore() {
      return this.itemper * this.tgvalue
    }
  },
  watch: {
    reqkhscore(n) {
      // if (n < 0)
      // {
      //   this.tgvalueShow = true;
      //   this.disableds = false;
      // } else if (n > this.qiwangScore)
      // {
      //   this.tgvalueShow = true;
      //   this.disableds = false;
      // } else
      if (n == '') {
        this.tgvalueShow = true
        this.disableds = false
      } else {
        this.tgvalueShow = false
        this.disableds = true
      }

      console.log(n, this.tgvalue, 'reqkhscorereqkhscore')
    },
    reqkhres(n) {
      if (n) {
        this.disableds = true
      }
    }
  },
  created() {
    this.loginUserImage = window.sessionStorage.getItem('loginUserImage')
  },
  methods: {
    onClickLeft() {
      if (this.isshensu == 'false') {
        this.$router.push({
          path: '/jxMyAssResults',
          query: { is360: this.is360, khtimeid: this.khtimeid }
        })
      } else {
        let goToApplyObj = window.sessionStorage.getItem('goToApplyObj')
        let obj = JSON.parse(goToApplyObj)
        obj.khtimeid = this.khtimeid
        this.$router.push({
          path: '/jxMyAssResultsList',
          query: obj
        })
      }
    },
    // 获取期望等级
    getQWLevel() {
      getJxLevel({ username: this.userInfo.username }).then(res => {
        if (res.data.length > 0) {
          res.data.forEach(ele => {
            this.levelColumns.push(ele.levelname)
          })
        }
      })
    },
    // 期望等级点击事件
    showLevelPicker() {
      this.getQWLevel()
      this.showPicker = true
    },
    onConfirm(value) {
      this.reqkhres = value
      this.showPicker = false
      this.levelColumns = []
    },
    // 提交
    submit() {
      let dtid = 0
      if (this.sstype == '按结果申诉') {
        dtid = 0
        if (!this.reqkhres) {
          Toast(this.$t('jxMyAssResultsAppeal.araTxt20'))
          return
        }
      } else {
        dtid = this.mautoid
      }
      if (this.sstype == '按指标申诉') {
        // if (this.reqkhscore < 0)
        // {
        //   Toast(this.$t('jxMyAssResultsAppeal.araTxt24') + " ~ " + this.qiwangScore + this.$t('jxMyAssResultsAppeal.araTxt25'));
        //   return;
        // } else if (this.reqkhscore > this.qiwangScore)
        // {
        //   Toast(this.$t('jxMyAssResultsAppeal.araTxt24') + " ~ " + this.qiwangScore + this.$t('jxMyAssResultsAppeal.araTxt25'));
        //   return;
        // } else
        if (this.reqkhscore == '') {
          Toast(this.$t('jxMyAssResultsAppeal.araTxt26'))
          return
        } else if (isNaN(this.reqkhscore)) {
          Toast(this.$t('jxMyAssResultsAppeal.araTxt22'))
          return
        } else {
          this.tgvalueShow = false
        }
        // if (this.reqkhscore != 0)
        // {
        //   if (!this.reqkhscore)
        //   {
        //     Toast(this.$t('jxMyAssResultsAppeal.araTxt21'));
        //     return
        //   } else
        //   {
        //     if (isNaN(this.reqkhscore))
        //     {
        //       Toast(this.$t('jxMyAssResultsAppeal.araTxt22'));
        //       return
        //     } else
        //     {
        //       if (this.reqkhscore < 0)
        //       {
        //         Toast(this.$t('jxMyAssResultsAppeal.araTxt23'));
        //         return
        //       }
        //     }
        //   }
        // }
      }

      if (!this.apreason) {
        Toast(this.$t('jxMyAssResultsAppeal.araTxt27'))
        return
      }
      // tgvalue
      postJxSs({
        mautoid: this.itemautoid,
        sstype: this.sstype,
        dtid: dtid,
        reqkhscore: this.reqkhscore,
        reqkhres: this.reqkhres,
        apreason: this.apreason,
        remark: this.remark,
        username: this.userInfo.username
      }).then(res => {
        if (res.data[0].info) {
          Toast(res.data[0].info)
        } else {
          Toast.success(this.$t('jxMyAssResultsAppeal.araTxt28'))
          this.onClickLeft()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.myAssAppeal {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .pppppp {
    color: #ef2e45;
    margin: 0;
    margin-bottom: 0.26667rem;
    font-size: 32px;
    padding-left: 6.2em;
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 90px);
    .qwdflll {
      padding: 0 0 20px;
      &::after {
        display: none !important;
      }
      /deep/.van-cell__title {
        span {
          color: #ef2e45;
        }
      }
      /deep/.van-field__body {
        input {
          border: 1px solid #ef2e45;
          padding-left: 20px;
        }
      }
    }
    .app_details {
      position: relative;
      /deep/.van-cell {
        .van-cell__title {
          color: #ababab;
        }
        .van-cell__value {
          .van-field__body {
            .van-field__control {
              color: #ababab;
            }
          }
        }
      }
      /deep/.empcode {
        .van-cell__title {
          color: #ababab;
        }
        .van-cell__value {
          .van-field__body {
            .van-field__control {
              color: #2b8df0 !important;
            }
          }
        }
      }
      .van-cell::after {
        border-bottom: 0;
      }
      .presonAvar {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 122px;
        min-width: 122px;
        height: 122px;
        border-radius: 122px;
        border: 2px solid #2b8df0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .app_reason {
      margin: 30px 0;
      padding: 30px;
      background: #fff;
      .text {
        font-size: 28px;
        color: #ef2e45;
      }
      .reqkhres {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
        border-radius: 12px;
        padding: 4px;
        margin-bottom: 30px;
        .van-cell::after {
          border-bottom: 0;
        }
      }
      .reqkhres_cell {
        padding: 20px 0;
        margin-bottom: 20px;
        /deep/.van-field__label {
          color: #ef2e45;
        }
      }
      .thrid_desc {
        margin: 16px 4px 4px 4px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
        border-radius: 12px;
        .brief {
          border-radius: 12px;
        }
      }
    }
    .app_desc {
      margin: 30px 0;
      padding: 30px;
      background: #fff;
      .text {
        font-size: 28px;
        // color: #ef2e45;
      }
      .thrid_desc {
        margin: 16px 4px 4px 4px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
        border-radius: 12px;
        .brief {
          border-radius: 12px;
        }
      }
    }
    .app_button {
      background: #fff;
      padding: 20px 100px;
      .van-button {
        border-radius: 20px;
      }
    }
  }
}
</style>
